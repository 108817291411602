<template>
  <div class="new-password-modal">
    <el-dialog width="550px" :visible="isVisible" :before-close="closeModal" :close-on-click-modal="false">

      <template slot="title">
        <div class="new-password-modal__header">
          <div class="new-password-modal__header-title">Забыли свой пароль?</div>
        </div>
      </template>

      <div class="new-password-modal__content">
        <div class="new-password-modal__content-text">Пожалуйста, введите новый пароль</div>
        <div v-loading="loading" style="width: 100%">
          <form @submit.prevent="handleSubmit"
                class="new-password-modal__content-input">
            <form-input label="Новый пароль"
                        class="new-password-modal__email-input"
                        type="password"
                        v-model.trim="newPassword"
                        :validator="$v.newPassword"
                        maxLength="64"
                        placeholder="Введите новый пароль"/>

            <div class="new-password-modal__btn"
                 @click="handleSubmit">
              Изменить пароль
            </div>

          </form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FormInput from "../../../components/FormInput";

import {validationMixin} from "vuelidate";
import {required,minLength,maxLength} from 'vuelidate/lib/validators'
import {validate} from "../../../../../utils/validators";

export default {
  name: "NewPasswordModal",
  mixins: [validationMixin],
  components: {
    FormInput
  },
  data() {
    return {
      newPassword: '',
      loading: false,
      isVisible: false
    }
  },
  methods: {
    openModal: function () {
      this.email = ''
      this.isVisible = true
    },
    closeModal: function () {
      this.email = ''
      this.isVisible = false
      this.loading = false

      this.$v.$reset()

      this.$router.push('/login')
    },
    handleSubmit: function () {
      if (validate(this.$v)) {
        this.$emit('updatePassword',this.newPassword)
        this.closeModal()
      }
    },
  },

  validations: {
    newPassword: {required: required, minLength: minLength(6),maxLength: maxLength(64)}
  }
}
</script>

<style lang="scss">
.new-password-modal {
  .el-dialog {
    border-radius: 20px;
  }

  .el-dialog__headerbtn {
    .el-dialog__close{
      color: #121212;
      font-weight: 600;
    }

  }

  &__header {
    display: flex;
    justify-content: center;
  }

  &__header-title {
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;


    width: 290px;
    padding-bottom: 12px;

    border-bottom: 2px solid #1454F2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content-text {
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;

    color: #121212;

    margin-bottom: 48px;
  }

  &__content-input {
    display: flex;
    align-items: center;
    flex-direction: column;

    .form-input__error-text {
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  &__email-input {
    margin-bottom: 32px;
    width: 100%;

    .form-input__label {
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;

      span {
        margin-bottom: 5px;
      }
    }

    .form-input__input {
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 4px;

      &::placeholder {
        font-size: 16px;
      }
    }

  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;

    width: 300px;
    height: 58px;

    background: #1454F2;
    border-radius: 50px;

    cursor: pointer;

    transition: 0.2s;
    &:hover {
      background: #1454f2de;
    }
  }
}

</style>

<template>
  <div v-loading="true" style="width: 100vw;height: 100vh">
    <new-password-modal ref="newPasswordModal"
                        @updatePassword="updatePassword"/>
  </div>

</template>

<script>
import {authService} from "../auth.service";
import {notificationsHelper} from '@/helpers/notifications.helper'

import NewPasswordModal from "./modal/NewPasswordModal";

export default {
  name: "ResetPassword",
  components: {NewPasswordModal},
  computed: {
    token() {
      return this.$route.params.token
    }
  },
  mounted() {
    authService.isValidToken(this.$route.params.token).then(() => {
      this.$refs.newPasswordModal.openModal()
    })
  },
  methods: {
    updatePassword(newPassword) {
      authService.updatePassword(this.token, newPassword).then(() => {
        notificationsHelper.success('Пароль обновлен')
      })
    }
  }
}
</script>

<style scoped>

</style>